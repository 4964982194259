<template>
  <div>
    <p class="v-label theme--light">
      {{ label }}
    </p>
    <VueEditor
      :value="value"
      :editor-toolbar="customToolbar"
      use-custom-image-handler
      @image-added="handleImageAdded"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor';
import { getGoghaImageUrl } from '@front.backoffice/common';
import { v5 as uuidv5, v4 as uuidv4 } from 'uuid';
import { TYPE_WYSIWYG, NAME_IMAGE } from '../../../components/gogha/common';

// переназначаем класс для выравнивания текста
const AlignClass = Quill.import('formats/align');
AlignClass.keyName = 'text';
Quill.register(AlignClass, true);

// переназначаем класс для отступа слева
const IndentClass = Quill.import('formats/indent');
IndentClass.keyName = 'pl';
Quill.register(IndentClass, true);

export default {
  name: 'TextEditor',
  components: { VueEditor },
  props: {
    /**
     * Смотрим возможные имена и типы здесь: https://burning-heart.atlassian.net/wiki/spaces/TT/pages/1231749896
     */
    type: {
      type: String,
      default: TYPE_WYSIWYG,
    },
    name: {
      type: String,
      default: NAME_IMAGE,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    customToolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ color: [] }, { background: [] }],
      ['link', 'image'],
      ['clean'],
    ],
  }),
  methods: {
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (file) {
        try {
          // Загружаем изображение
          const {
            type, name,
          } = this;

          const data = new FormData();
          data.append('image', file);

          // генерируем uuid для картинки
          const uuid = this.generateUuid();

          await this.$di.api.Gogha.UploadImage(type, uuid, name, data);
          this.$di.notify.snackSuccess('Картинка успешно загружена');
          resetUploader();

          // Получаем изображение и вставляем в редактор
          const imgUrl = getGoghaImageUrl({
            uuid,
            name,
            type,
          });
          Editor.insertEmbed(cursorLocation, 'image', imgUrl);
        } catch (error) {
          this.$di.notify.errorHandler(error);
        }
      }
    },
    generateUuid() {
      const MY_NAMESPACE = uuidv4(); // можно и фиксированный uuid, теоретически
      return uuidv5(`${Date.now()}`, MY_NAMESPACE);
    },
  },
};
</script>
